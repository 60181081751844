
import CryptoJS from "crypto-js";
import _ from 'lodash'

export const getClassFee = (category, info) => {
  return _.find(info, (inf) => inf.className === category)?.fees;
};


export const toCurrency = (number) => {
  return "GH¢ " + Number(number.toFixed(2)).toLocaleString();
};

export const maxDate = () => {
  var today = new Date();
  var day = today.getDate();
  var month = today.getMonth() + 1;
  var year = today.getFullYear();

  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }

  return year + "-" + month + "-" + day;
};

export const composeMessage = (info) => {
  return String(
    `Dear Parent, An Amount of ${info.amount}GHS has been paid for ${
      info.name
    } for ${info.fee_type} at ${info.date?.slice(0, 10)}. Current Balance: ${
      info.balance
    } Receipt ID: ${info.id} Thank You`
  );
};

export const checkFile = (file) => {
  const fileType = file.type;

  const fileSize = file.size / 1024;
  const accepts = ["application/pdf", "image/png", "image/jpg", "image/jpeg"];

  return accepts.includes(fileType) && fileSize <= 2 * 1024 * 1024;
};

const encryptKey = "x-constants";

export const encryptData = (text) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    encryptKey
  ).toString();

  return data;
};

export const decryptData = (text) => {
  try {
    const bytes = CryptoJS.AES.decrypt(text, encryptKey);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  } catch (error) {
    return null;
  }
};

export const messageTemplates = [
  {
    template:`No Template Selected`
  },
  {
    template:`Hello Parent, Please do well to settle {name} outstanding  fees for the term if you haven't already done so. 
    Payments can be made via momo number 0558688053,
    USSD code *771*10# or by bank deposits at any of the calbank branches (Market circle, harbour, Tacotel). Thank you`
  }, 
  {
    template:`Dear Parent, this is a reminder that {name} has an outstanding school fees of {amount} cedis. 
    End-of-term exams begin next week and it is important that all fees are settled by 8th December to ensure {name} can participate. 
    Thank you`
  }, 
  {
    template:`Dear Parent, {name} has outstanding fees of {amount} cedis. Please arrange for immediate payment. If already paid, kindly ignore this message. Thank you.`
  }, 
  {
    template:`Dear Parent, An Amount of {amount} GHS has been paid for {name} for {fee_type} at {date}. Current Balance: {balance} Receipt ID: {id} Thank You`
  }
]


