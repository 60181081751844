import { useEffect, useState } from "react";
import { composeMessage, messageTemplates } from "../../functions";
import { toCurrency } from "../../functions";
import { motion } from "framer-motion";
import { proxy, readDocuments } from "../../services/calls";
import { uploadData } from "../../services/calls";
import { Feedback } from "./feedback";
import axios from "axios";

export default function Broadcast({ senderID, close }) {
  const [records, setRecords] = useState([]);
  const [template, setTemplate] = useState(false);
  const [selected, setSelected] = useState("");
  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    readDocuments({
      path: `/fees/find-records?date=${new Date().toISOString().slice(0, 10)}`,
      getData: setRecords,
    });
  }, []);

  //eslint-disable-next-line
  const sendMessage = async (info) => {
    var message = composeMessage(info);

    const data = {
      message,
      target: [info.target],
      sender_id: senderID,
    };

    try {
      await uploadData({
        path: "/messages/send-sms",
        data,
      });
      setLoading(false);
    } catch (err) {
      setResponse({
        message: err.message,
        open: true,
        type: "error",
      });
    }
  };

  const sendMessages = async () => {
    const messages = [];
    for (var rec of records) {
      if (
        parseInt(rec.phone) === 0 ||
        parseInt(rec.phone).toString().length < 10 ||
        !rec.phone?.startsWith(0)
      ) {
        continue;
      } else {
        messages.push({
          name: rec.last_name + " " + rec.first_name,
          fee_type: rec.fee_type,
          amount: rec.amount,
          target: rec.phone,
          date: rec.date_paid?.slice(0, 10),
          balance: rec.balance,
          id: rec.payment_id,
          paid_by: rec.paid_by,
        });
      }
    }
    for (var info of messages) {
      await sendMessage(info);
    }
  };

  const alertDebtors = async () => {
    const { smsAPIKey, senderID, dbName } = JSON.parse(
      localStorage.getItem("school")
    );
    setTemplate(false);
    // console.log(smsAPIKey, senderID)
    if (selected === "1" || selected === "2" || selected === "3") {
      await axios
        .post(
          `${proxy}/messages/alert-debtors?key=${smsAPIKey}&senderID=${senderID}&template=${selected}`,
          {},
          {
            headers: {
              dbName: dbName,
            },
          }
        )
        .then(() =>
          setResponse({
            open: true,
            message: "Messages sent successfully",
            type: "success",
          })
        )
        .catch((err) =>
          setResponse({
            open: true,
            message: "Something went wrong sending messages",
            type: "error",
          })
        );

      return;
    }

    if (selected === "4") {
      sendMessages();
    }
  };

  return (
    <motion.div
      initial={{ x: 100, y: 10 }}
      animate={{ x: 10, y: 0 }}
      transition={{ type: "spring" }}
      exit={{ y: 100, x: 10 }}
      className="fixed top-0 left-0 w-screen z-50 p-10 h-screen bg-white/40 backdrop-blur-sm"
    >
      {response.open && (
        <Feedback setOpen={() => setResponse({ open: false })} {...response} />
      )}
      <div className="flex flex-col absolute bottom-10 p-2 right-10 h-[80vh] rounded-t-md w-[35%] shadow-md bg-white">
        <div className="relative">
          <h3 className="text-lg font-bold">Broadcast Messages</h3>
          <p className="text-sm">
            Preview payments made today and then notify payments through SMS to
            the payments.
            <p className="text-xs py-2">Payment Today</p>
          </p>
        </div>
        <div className="flex flex-col p-2 gap-2 h-[60vh] overflow-y-auto">
          {records?.map((info, idx) => (
            <div
              className="flex p-2 bg-gray-50 justify-between items-center"
              key={idx}
            >
              <p className="text-sm truncate w-[70%]">
                {info.last_name + " " + info?.first_name}
              </p>
              <p className="text-sm">{toCurrency(info?.amount)}</p>
            </div>
          ))}
        </div>
        {/* Trigger Template selection */}
        {template && (
          <div className="flex w-[98%] p-3 bg-gray-700  absolute bottom-0 h-[50vh] flex-col gap-2 justify-start items-start">
            <h1 className="text-lg text-gray-50 py-3">
              Select Message Template
            </h1>
            <select
              onChange={(e) => setSelected(e.target.value)}
              name="messageType"
              id="messageType"
              className="w-full p-3 bg-gray-100"
            >
              <option value="0">Select Template</option>
              <option value="1">Normal Messaging Alert</option>
              <option value="2" disabled>
                Notice for Exams
              </option>
              <option value="3">Message with Amount</option>
              <option value="4">Today's Payments</option>
            </select>
            {/* Preview of template when selected... */}
            {selected && (
              <div className="w-full p-3 bg-white">
                {messageTemplates[parseInt(selected)]?.template}
              </div>
            )}

            <div className="flex items-center gap-2 w-full mt-3">
              <button
                disabled={!template}
                className="p-2 bg-yellow-300 w-5/6 text-black hover:bg-black hover:text-yellow-300 hover:border-white hover:border-2 transition-all delay-75 text-center"
                onClick={() => alertDebtors()}
              >
                {loading ? "Sending Messages... " : "Click to Send Message"}
              </button>
              <button
                onClick={() => close()}
                className="bg-red-500 text-white p-2 flex-1"
              >
                Close
              </button>
            </div>
          </div>
        )}

        <div className="flex w-full items-center gap-2 mt-2">
          <button
            onClick={() => setTemplate(!template)}
            className="p-2 bg-red-500 text-sm text-white w-full"
          >
            {"Open Message Templates"}
          </button>
        </div>
      </div>
    </motion.div>
  );
}
